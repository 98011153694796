import React from "react"
import { onFormBuilderSubmit2 } from "../services/form-builder"

const NewIndexPage = () => {
  return (
    <>
      <button
        onClick={() => {
          console.log("clicked")
        }}
      >
        Send data
      </button>
    </>
  )
}

export default NewIndexPage
